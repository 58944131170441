import Swiper from "swiper/bundle";
import FirstViewEndObserver from "../extended_class/FirstViewEndObserver";
import GET_ARTICLES from "../class/getArticles";

const [d,header,mainvisual,mq] = [document,document.querySelector('.rwd002-header'),document.querySelector('.mainvisual'),window.matchMedia( "(max-width: 1023px)" ) ];

class FirstViewEndObserverHome extends FirstViewEndObserver {
  constructor (opt) { super(opt) }

  intersectIn () {
    header.classList.add('is-home-first-view');
    mainvisual.classList.add('is-home-first-view');
  }
  intersectOut () {
    header.classList.remove('is-home-first-view');
    mainvisual.classList.remove('is-home-first-view');
  }
}

class Blocks
{
  constructor()
  {
    this.init();
  }

  init()
  {
    this.news('.block-topicks__list[data-plugin="getArticles"]');
  }

  mainvisual() {
    //メインスライダー
    new Swiper('.mainvisual-slider[data-plugin="mainSlider"]',{
      direction: 'vertical',
      loop: true,
      effect: 'fade',
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 4000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mainvisual__slider-pager",
        clickable: true,
      },
    });
  }

  news(selector)
  {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}__item">
        <a href="${item.href}" class="block-${content}__item-link">
          <div class="block-${content}__item-img">
            <img src="${item.thumb}" alt="${item.title}">
          </div>
          <!-- /.block-${content}__item-img -->
          <div class="block-${content}__item-body">
            ${(item.category)? `<span class="cat">${item.category.name}</span>`: ''}
            <span class="subject">${item.title}</span>
            <span class="date">${item.date}</span>
          </div>
          <!-- /.block-${content}__item-body -->
        </a>
        <!-- /.block-${content}__item-link -->
      </li>
      <!-- /.block-${content}__item -->
    `;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

const domReady = TPL =>
{
  header.classList.add('is-home-first-view');
  new FirstViewEndObserverHome;
  let blocks = new Blocks;
  window.addEventListener('load', () => {
    blocks.mainvisual();
  });
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}