import Utility from "./class/utility";
import CUSTOMIZE from "./extended_class/customize";
import HOME from "./module/home";
import ABOUTUS from './module/aboutus';
import PRODUCTS from './module/products';
import FEATURE from './module/feature';

// ユーティリティクラスのインスタンス化
const UTIL = new Utility();

// テンプレートインスタンス化（promise登録）
const TPL = new CUSTOMIZE();

// URLによる関数の実行
UTIL.loader( (request, path) => {
  switch( path ){
    case '/': HOME(); break
    case '/aboutus/': ABOUTUS(); break
    case '/products/': PRODUCTS(); break
    case '/feature/': FEATURE(); break
  }
  // switch( request ){}
});

// テンプレートインスタンスの初期化（イベント発火）
TPL.init();