import Utility from '../class/utility';
const [w] = [window];

export default function ABOUTUS() {
    // ユーティリティクラスのインスタンス化
    const UTIL = new Utility();

    w.jQuery = $;

    UTIL.addScript({
        src: '/assets/js/vendor/jquery.event.move.js',
    });
    UTIL.addScript({
        src: '/assets/js/vendor/jquery.twentytwenty.js',
    });
    $(() => {
        $('.rwd002-gnav-item a.current').removeClass('current');
        $('[data-plugin=currentMenu]').currentMenu({ mode: 0, default: 999 });
    });

    $(() => {
        Promise.all([window.load['aboutus-01']]).then(() =>
        {
            $('#detailimage').twentytwenty();
        });
    });
}
