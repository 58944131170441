import GET_ARTICLES from '../class/getArticles';

const [w, d] = [window, document];

class Blocks {
    constructor() {
        this.init();
    }

    init() {
        this.products('.products-detail-list[data-plugin="getArticles"]');
    }

    products(selector) {
        const recruit_block = new GET_ARTICLES(selector);
        recruit_block.makeItem = (item, content) => `
    <a href="${item.href}" class="products-detail-item">
        <figure class="products-detail-item__img">
          <img src="${item.thumb}" alt="${
            item.title
        }" width="200" loading="lazy">
        </figure>
        <h4 class="subject">
            <span>${item.title.str_truncate(24)}</span>
        </h4>
    </a>`;
        // recruit_block.makeItem = (item) => console.log(item);
        // recruit_block.render();
        recruit_block.render().then(() => {
            // ここでcontainer内の記事の数をチェックします
            const articles = d.querySelectorAll(
                `${selector} a.products-detail-item`
            );
            if (articles.length === 0) {
                $('.products-detail-list').hide();
                // const container = d.querySelector(selector);
                // if (container) {
                //     container.innerHTML = "公開されている記事はありません。";
                // }
            }
        });
    }
}

const onLoadHtml = () => {
    let blocks = new Blocks();
};

export default function PRODUCTS() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('rwd002.beforeDomready', (e) => domReady(e.detail));
    $(() => Promise.all([w.load['loadHtml']]).then(onLoadHtml));
}

// export default function PRODUCTS () {
//   // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
//   d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
// }
